import { useState, useEffect } from "react";
import Context from "../../Context";
import { Container, Text } from "../../components/base";
import { Loading } from "../../components/modules";
import {
  ScrollArea,
  Separator,
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";

export const Dashboard = () => {
  const { callApi, isDarkTheme, themes, configs, profileState } = Context();
  const [dashboard, setDashboard] = useState(false);
  const [showIncomeChart, setShowIncomeChart] = useState(false);
  useEffect(() => {
    getDashboard();
    const interval = setInterval(() => {
      getDashboard();
    }, 60000);
    return () => clearInterval(interval);
  }, [configs]);

  const getDashboard = async () => {
    let { data } = await callApi("dashboard-getDashboard");
    data.historySale = Object.entries(data.historySale || {})
      .map(([key, value]) => {
        return { date: key, size: value.size, income: value.income };
      })
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    data.historyUser = Object.entries(data.historyNewUser || {})
      .map(([key, value]) => {
        return { date: key, size: value.size };
      })
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    data.totalIncome = Object.values(data.historySale).reduce((acc, value) => {
      acc += value.income;
      return acc;
    }, 0);

    data.roundDateNewUser = Object.values(data.historyNewUser || {}).reduce(
      (acc, value) => {
        acc += Number(value.size);
        return acc;
      },
      0
    );

    for (let count = 0; count <= 99; count++) {
      const currentKey = count > 9 ? `${count}` : `0${count}`;

      if (!data.currentLottoLastTwo[currentKey]) {
        data.currentLottoLastTwo[currentKey] = 0;
      }
    }

    console.log(
      "🚀 ~ getDashboard ~ data:",
      Object.keys(data.currentLottoLastTwo).length
    );

    setDashboard(data);
  };

  const chartConfig = {
    income: {
      label: "รายได้",
      color: "#FA812F",
    },
    size: {
      label: "ยอดขาย",
      color: "#52B788",
    },
  };
  return (
    <div className="pb-96">
      <Summary dashboard={dashboard} />

      {/* three panel */}
      <div className="flex gap-3">
        <Container className={`bg-gray-950 p-3 w-1/3 h-fit shadow-md`}>
          <Text className={`text-2xl p-4 text-white font-semibold pb-3`}>
            เลขท้าย 2 ตัวคงเหลือ (20 อันดับ)
          </Text>
          {dashboard ? (
            <ScrollArea className="h-[600px] border-0">
              <div className="p-4">
                <div className="flex bg-gray-950 sticky top-0 justify-between">
                  <h4 className="mb-3 text-white text-xl font-medium leading-none">
                    เลขท้าย
                  </h4>
                  <h4 className="mb-3 text-white text-xl font-medium leading-none">
                    จำนวน (ใบ)
                  </h4>
                </div>
                {Object.entries(dashboard?.currentLottoLastTwo)
                  .sort((a, b) => b[1] - a[1])
                  .map(([key, value], index) =>
                    index < 20 ? (
                      <div key={index}>
                        <div className="hover:bg-gray-800 hover:scale-105 duration-150 p-3 rounded-2xl">
                          <div key={key} className={`flex justify-between`}>
                            <p className="text-white text-xl font-semibold">
                              {key}
                            </p>
                            <p className="text-white text-xl font-semibold">
                              {value.toLocaleString()}
                            </p>
                          </div>
                        </div>
                        <Separator className="my-1 mx-auto" />
                      </div>
                    ) : undefined
                  )}
              </div>
            </ScrollArea>
          ) : (
            <div className="h-[600px] flex items-center justify-center">
              <Loading className={`text-white`} />
            </div>
          )}
        </Container>
        <Container className={`p-3 w-1/3 h-fit shadow-md`}>
          <Text className={`text-2xl p-4 font-semibold pb-3`}>
            เลขท้าย 2 ตัวไกล้หมด (40 อันดับ)
          </Text>
          {dashboard ? (
            <ScrollArea className="h-[600px] border-0">
              <div className="p-4">
                <div className="flex sticky top-0 bg-white justify-between">
                  <h4 className="mb-3 text-xl opacity-45 font-medium leading-none">
                    เลขท้าย
                  </h4>
                  <h4 className="mb-3 text-xl opacity-45 font-medium leading-none">
                    จำนวน (ใบ)
                  </h4>
                </div>
                {Object.entries(dashboard?.currentLottoLastTwo)
                  .sort((a, b) => a[1] - b[1])
                  .map(([key, value], index) =>
                    index < 40 ? (
                      <div key={index}>
                        <div className="hover:bg-gray-100 hover:scale-105 duration-150 p-3 rounded-2xl">
                          <div key={key} className={`flex justify-between`}>
                            <Text className="text-xl font-semibold">{key}</Text>
                            <Text className="text-xl font-semibold">
                              {value.toLocaleString()}
                            </Text>
                          </div>
                        </div>
                        <Separator className="my-1 mx-auto" />
                      </div>
                    ) : undefined
                  )}
              </div>
            </ScrollArea>
          ) : (
            <div className="h-[600px] flex items-center justify-center">
              <Loading className={``} />
            </div>
          )}
        </Container>
        <Container className={`p-3 w-1/3 h-fit shadow-md`}>
          <Text className={`text-2xl p-4 font-semibold pb-3`}>
            สรุปจำนวนเลขชุด
          </Text>
          {dashboard ? (
            <ScrollArea className="h-[600px] border-0">
              <div className="p-4">
                <div className="flex sticky top-0 bg-white justify-between">
                  <h4 className="mb-3 text-xl opacity-45 font-medium leading-none">
                    ชุด
                  </h4>
                  <h4 className="mb-3 text-xl opacity-45 font-medium leading-none">
                    จำนวน (ชุด)
                  </h4>
                </div>
                {Object.entries(dashboard?.currentBlocks).map(
                  ([key, value], index) => (
                    <div key={index}>
                      <div className="hover:bg-gray-100 hover:scale-105 duration-150 p-3 rounded-2xl">
                        <div key={key} className={`flex justify-between`}>
                          <Text className="text-xl font-semibold">{key}</Text>
                          <Text className="text-xl font-semibold">
                            {value.toLocaleString()}
                          </Text>
                        </div>
                      </div>
                      <Separator className="my-1 mx-auto" />
                    </div>
                  )
                )}
              </div>
            </ScrollArea>
          ) : (
            <div className="h-[600px] flex items-center justify-center">
              <Loading className={``} />
            </div>
          )}
        </Container>
      </div>

      {/* sale chart */}
      <Container className={`mt-3 p-0 min-h-96 overflow-hidden`}>
        <div className="flex h-40 items-center ">
          <div className="flex flex-col h-full justify-center  flex-grow gap-2 pl-24 hover:scale-105 duration-150 cursor-default hover:bg-slate-100">
            <Text className={`text-4xl font-semibold`}>ข้อมูลการขาย</Text>
            <Text className={`font-medium text-xl text-gray-500`}>
              งวด {configs.roundDate}
            </Text>
          </div>
          <Separator orientation="vertical" className="h-full" />
          <div
            onClick={() => setShowIncomeChart(false)}
            className="flex flex-col flex-grow-0 justify-center px-14 h-full hover:scale-105 duration-150 cursor-pointer hover:bg-slate-100"
          >
            <Text className="text-lg">ยอดขายรวม</Text>
            <Text>
              <span className="text-4xl font-bold">
                {(dashboard.currentLottoSold || 0)?.toLocaleString()}
              </span>{" "}
              ใบ
            </Text>
          </div>
          <Separator orientation="vertical" className="h-full" />
          <div
            onClick={() => setShowIncomeChart(true)}
            className="flex flex-col h-full flex-grow-0 justify-center px-14  hover:scale-105 duration-150 cursor-pointer hover:bg-slate-100"
          >
            <Text className="text-lg">รายได้รวม</Text>
            <Text>
              <span className="text-4xl font-bold">
                {(dashboard.totalIncome || 0)?.toLocaleString()}
              </span>{" "}
              บาท
            </Text>
          </div>
        </div>
        <Separator className={`mb-3`} />
        <ChartContainer
          config={{
            size: {
              label: `ยอดขาย ${showIncomeChart ? "บาท" : "ใบ"} `,
              color: "#52B788",
            },
          }}
          className="h-[500px] p-3  w-full"
        >
          <BarChart accessibilityLayer data={dashboard.historySale}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={true}
              tickMargin={10}
              axisLine={true}
              tickFormatter={(value) => value}
            />

            <YAxis tickFormatter={(value) => value?.toLocaleString()} />
            <ChartTooltip
              content={<ChartTooltipContent className="bg-white" />}
            />
            {/* <ChartLegend content={<ChartLegendContent />} /> */}

            <Bar
              dataKey={showIncomeChart ? "income" : "size"}
              fill="rgb(59,176,101)"
              radius={4}
            />
          </BarChart>
        </ChartContainer>
      </Container>

      {/* new user chart */}
      <Container className={`mt-3 p-0 min-h-96 overflow-hidden`}>
        <div className="flex h-40 items-center ">
          <div className="flex flex-col h-full justify-center  flex-grow gap-2 pl-24 hover:scale-105 duration-150 cursor-default hover:bg-slate-100">
            <Text className={`text-4xl font-semibold`}>ข้อมูลผู้ใช้ใหม่</Text>
            <Text className={`font-medium text-xl text-gray-500`}>
              งวด {configs.roundDate}
            </Text>
          </div>
          <Separator orientation="vertical" className="h-full" />
          <div className="flex flex-col flex-grow-0 justify-center px-14 h-full hover:scale-105 duration-150 cursor-default hover:bg-slate-100">
            <Text className="text-lg">ผู้ใช้ใหม่งวดนี้</Text>
            <Text>
              <span className="text-4xl font-bold">
                {(dashboard.roundDateNewUser || 0)?.toLocaleString()}
              </span>{" "}
              บัญชี
            </Text>
          </div>
          <Separator orientation="vertical" className="h-full" />
          <div className="flex flex-col h-full flex-grow-0 justify-center px-14  hover:scale-105 duration-150 cursor-default hover:bg-slate-100">
            <Text className="text-lg">ผู้ใช้ทั้งหมด</Text>
            <Text>
              <span className="text-4xl font-bold">
                {(dashboard.currentUser || 0)?.toLocaleString()}
              </span>{" "}
              บัญชี
            </Text>
          </div>
        </div>
        <Separator className={`mb-3`} />
        <ChartContainer
          config={{
            size: {
              label: "ผู้ใช้ใหม่",
              color: "#52B788",
            },
          }}
          className="h-[500px] p-3  w-full"
        >
          <BarChart accessibilityLayer data={dashboard.historyUser}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={true}
              tickMargin={10}
              axisLine={true}
              tickFormatter={(value) => value}
            />

            <YAxis tickFormatter={(value) => value?.toLocaleString()} />
            <ChartTooltip
              content={<ChartTooltipContent className="bg-white" />}
            />
            {/* <ChartLegend content={<ChartLegendContent />} /> */}

            <Bar dataKey="size" fill="#FA812F" radius={4} />
          </BarChart>
        </ChartContainer>
      </Container>

      {/* <Container className="h-[400px] w-full grow rounded-[20px] shadow-xl">
        dashboard
      </Container> */}
    </div>
  );
};

const Summary = ({ dashboard }) => {
  return (
    <div className="grid grid-cols-5 gap-3 mt-2 mb-3">
      <div className="flex flex-col justify-between py-2 px-3 h-32 w-full col-span-1 bg-[#095256] text-white rounded-2xl duration-300">
        <p className="text-sm font-medium">สลากทั้งหมด</p>
        <div className="text-2xl text-center font-bold">
          {!dashboard || !dashboard?.currentLotto ? (
            <Loading />
          ) : (
            dashboard?.currentLotto?.toLocaleString()
          )}
        </div>
        <p className="text-sm text-right font-medium">ใบ</p>
      </div>
      <div className="flex flex-col justify-between py-2 px-3 h-32 w-full bg-[#52B788] text-white rounded-2xl  duration-300">
        <p className="text-sm font-medium">สลากที่ขายแล้ว</p>
        <div className="text-2xl text-center font-bold">
          {!dashboard || !dashboard?.currentLottoSold ? (
            <Loading />
          ) : (
            dashboard?.currentLottoSold?.toLocaleString()
          )}
        </div>
        <p className="text-sm text-right font-medium">ใบ</p>
      </div>
      <div className="flex flex-col justify-between py-2 px-3 h-32 w-full bg-[#592941] text-white rounded-2xl duration-300">
        <p className="text-sm font-medium">สลากที่ยังไม่ขาย</p>
        <div className="text-2xl text-center font-bold">
          {!dashboard || !dashboard?.currentLotto ? (
            <Loading />
          ) : (
            (
              dashboard.currentLotto - (dashboard.currentLottoSold || 0)
            )?.toLocaleString()
          )}
        </div>
        <p className="text-sm text-right font-medium">ใบ</p>
      </div>
      <div className="flex flex-col justify-between py-2 col-span-2 px-3 h-32 w-full bg-[#FA812F] text-white rounded-2xl duration-300">
        <p className="text-sm font-medium">สลากในตระกร้า</p>
        <div className="text-2xl text-center font-bold">
          {!dashboard ? (
            <Loading />
          ) : (
            dashboard.currentLottoInCart?.toLocaleString()
          )}
        </div>
        <p className="text-sm text-right font-medium">ใบ</p>
      </div>

      <div className="flex flex-col justify-between py-2 px-3 h-32 w-full col-span-1 bg-[#1F2F16] text-white rounded-2xl duration-300">
        <p className="text-sm font-medium">คำสั่งซื้อทั้งหมด</p>
        <div className="text-2xl text-center font-bold">
          {!dashboard ? (
            <Loading />
          ) : (
            dashboard?.currentOrderAll?.toLocaleString()
          )}
        </div>
        <p className="text-sm text-right font-medium"> ออเดอร์</p>
      </div>
      <div className="flex flex-col justify-between py-2 px-3 h-32 w-full bg-[rgb(59,176,101)] text-white rounded-2xl  duration-300">
        <p className="text-sm font-medium">คำสั่งซื้ออนุมัติ</p>
        <div className="text-2xl text-center font-bold">
          {!dashboard ? (
            <Loading />
          ) : (
            dashboard?.currentOrderApproved?.toLocaleString()
          )}
        </div>
        <p className="text-sm text-right font-medium"> ออเดอร์</p>
      </div>
      <div className="flex flex-col justify-between py-2 px-3 h-32 w-full bg-[#37beb0] text-white rounded-2xl duration-300">
        <p className="text-sm font-medium">คำสั่งซื้อจ่ายแล้ว</p>
        <div className="text-2xl text-center font-bold">
          {!dashboard ? (
            <Loading />
          ) : (
            dashboard?.currentOrderPaid?.toLocaleString()
          )}
        </div>
        <p className="text-sm text-right font-medium"> ออเดอร์</p>
      </div>
      <div className="flex flex-col justify-between py-2 px-3 h-32 w-full bg-[#FAB12F] text-white rounded-2xl duration-300">
        <p className="text-sm font-medium">คำสั่งซื้อรอจ่าย</p>
        <div className="text-2xl text-center font-bold">
          {!dashboard ? (
            <Loading />
          ) : (
            dashboard?.currentOrderPending?.toLocaleString()
          )}
        </div>
        <p className="text-sm text-right font-medium"> ออเดอร์</p>
      </div>
      <div className="flex flex-col justify-between py-2 px-3 h-32 w-full bg-[#c15b78] text-white rounded-2xl duration-300">
        <p className="text-sm font-medium">คำสั่งซื้อยกเลิก</p>
        <div className="text-2xl text-center font-bold">
          {!dashboard ? (
            <Loading />
          ) : (
            dashboard?.currentOrderCanceled?.toLocaleString()
          )}
        </div>
        <p className="text-sm text-right font-medium"> ออเดอร์</p>
      </div>
    </div>
  );
};
